import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import PDFLink from '../components/PDFLink';

import GLOBAL_MEDIA_QUERIES from '../constants/media-queries';

export default function Conte({ data }) {
  const [openedDescription, openDescription] = useState(null);
  const handleOpenDescription = slug => openDescription(slug === openedDescription ? null : slug);
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.tabletImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.tablet,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.desktop,
    },
  ];
  return <Fragment>
    <div className="container">
      <div className="grid">
        <div className="column-half last">
          <h1>Le conte : <em>Pilou, à la rescousse !</em></h1>
        </div>
        <div className="column-half first">
          <h2>Le conte</h2>
          <div className="btn-group">
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="conte-pilou" className="italic" title="Pilou, à la rescousse !" />
          </div>
          <h2>Les illustrations du conte</h2>
          <div className="btn-group">
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="conte_pilou_illus_seules" title="Les illustrations uniquement" />
          </div>
          <h2>Le tapuscrit</h2>
          <div className="btn-group">
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="tapuscrit" title="Le texte uniquement" />
          </div>
        </div>
      </div>
    </div>
    <div className="image-bg"><Img fluid={sources} /></div>
  </Fragment>
}

export const query = graphql`
query {
  mobileImage: file(relativePath: { eq: "conte-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 354, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  tabletImage: file(relativePath: { eq: "conte-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 668, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  desktopImage: file(relativePath: { eq: "conte-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 948, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
}
`;
